import React, { ReactElement, useContext, useState } from "react";
import api from "../../api";
import Button from "../../components/button";
import Input from "../../components/input";
import LogoMobile from "../../components/logoMobile";
import Title from "../../components/titel";
import { AppContext } from "../../contexts/appContext";
import language from "../../data/language";
import { formatMaxNumber, passwordsValidation } from "../../util";

import css from "./style/index.module.scss";

function ActivateAccount(): ReactElement {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [email, setEmail] = useState("");
  const [zip, setZip] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [year, setYear] = useState("");

  const { dispatch } = useContext(AppContext);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!(password && confirmPassword)) return;
    try {
      passwordsValidation(password, confirmPassword);
    } catch (e: any) {
      dispatch({ type: "SHOW_MESSAGE", payload: e.message });
      return;
    }

    dispatch({ type: "SHOW_LOADING" });
    setIsSubmitting(true);

    api
      .activateAccount({
        cardNumber,
        email,
        password,
        dob: `${year}-${month.length === 2 ? month : `0${month}`}-${
          day.length === 2 ? day : `0${day}`
        }`,
        zip,
      })
      .then(() => {
        dispatch({
          type: "SHOW_MESSAGE",
          payload: language.activationLinkSent,
        });
        window.location.hash = "";
      })
      .catch((e) => {
        console.log(e);
        dispatch({ type: "SHOW_MESSAGE", payload: e.message });
      })
      .finally(() => {
        setIsSubmitting(false);
        dispatch({ type: "HIDE_LOADING" });
      });
  };

  const onCardNumberChange = (value: string) => setCardNumber(value);
  const onPasswordChange = (value: string) => setPassword(value);
  const onEmailChange = (value: string) => setEmail(value);
  const onConfirmPasswordChange = (value: string) => setConfirmPassword(value);
  const onMonthChange = (value: string) => setMonth(formatMaxNumber(value, 12));
  const onDayChange = (value: string) => setDay(formatMaxNumber(value, 31));
  const onYearChange = (value: string) =>
    setYear(formatMaxNumber(value, new Date().getFullYear()));
  const onZipChange = (value: string) => setZip(value);

  return (
    <article>
      <LogoMobile />
      <Title content={language.activateAccount} />

      <form
        method="POST"
        action="/dreamcatcher/activate"
        onSubmit={handleSubmit}
      >
        <div className={css.description}>
          <div>
            {language.activateDescription}{" "}
            <strong>{language.allFieldsrequired}</strong>
          </div>
        </div>
        <div className={css.field}>
          <Input
            onChange={onCardNumberChange}
            value={cardNumber}
            uniqueId="a-cardNumber"
            label={language.cardNumber}
            required={true}
            maxLength={30}
          />
        </div>

        <div className={css.field}>
          <Input
            onChange={onEmailChange}
            value={email}
            uniqueId="a-email"
            label={language.email}
            required={true}
            maxLength={50}
            type="email"
          />
        </div>
        <div className={css.field}>
          <Input
            onChange={onZipChange}
            value={zip}
            uniqueId="a-zipCode"
            label={language.zipCode}
            required={true}
            maxLength={15}
          />
        </div>
        <div className={css.field}>
          <Input
            onChange={onPasswordChange}
            value={password}
            uniqueId="a-password"
            label={language.password}
            required={true}
            maxLength={50}
            type="password"
          />
        </div>
        <div className={css.field}>
          <Input
            onChange={onConfirmPasswordChange}
            value={confirmPassword}
            uniqueId="a-confirmPassword"
            label={language.confirmPassword}
            required={true}
            maxLength={50}
            type="password"
          />
        </div>
        <div className={css.groupFields}>
          <div>
            <strong>{language.birthdate}</strong>
          </div>
          <div className={css.groupFieldsWrap}>
            <Input
              onChange={onMonthChange}
              value={month}
              uniqueId="a-mm"
              label={language.mm}
              required={true}
              maxLength={2}
            />
            <Input
              onChange={onDayChange}
              value={day}
              uniqueId="a-dd"
              label={language.dd}
              required={true}
              maxLength={2}
            />
            <Input
              onChange={onYearChange}
              value={year}
              uniqueId="a-yyyy"
              label={language.yyyy}
              required={true}
              maxLength={4}
              minLength={4}
            />
          </div>
        </div>

        <div className={css.submit}>
          <Button
            submit={true}
            label={language.submitButton}
            login={true}
            disabled={isSubmitting}
          />
        </div>
      </form>
    </article>
  );
}

export default ActivateAccount;
