import React from "react";

import css from "./style/index.module.scss";

function Footer() {
  return (
    <footer className={css.component}>
      <ul className={css.links}>
        <li>
          <a href="#forgot">Forgot Password?</a>
        </li>
        <li>
          <a
            href="https://www.snocasino.com"
            rel="noopener noreferrer"
            target="_blank"
          >
            Snoqualmie Casino
          </a>
        </li>
      </ul>
    </footer>
  );
}

export default Footer;
