import React, { ReactElement, useContext, useState } from "react";
import api from "../../api";
import Button from "../../components/button";
import Input from "../../components/input";
import LogoMobile from "../../components/logoMobile";
import Title from "../../components/titel";
import { AppContext } from "../../contexts/appContext";
import language from "../../data/language";
import { passwordsValidation } from "../../util";
import css from "./style/index.module.scss";

type Props = {
  code: string;
};
function ResetPassword({ code }: Props): ReactElement {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { dispatch } = useContext(AppContext);

  const onPasswordChange = (value: string) => setPassword(value);
  const onConfirmPassword = (value: string) => setConfirmPassword(value);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!(password && confirmPassword)) return;
    try {
      passwordsValidation(password, confirmPassword);
    } catch (e: any) {
      dispatch({ type: "SHOW_MESSAGE", payload: e.message });
      return;
    }

    dispatch({ type: "SHOW_LOADING" });
    setIsSubmitting(true);

    api
      .resetPassword({ password, code })
      .then(() => {
        dispatch({
          type: "SHOW_MESSAGE",
          payload: language.passwordChanged,
        });
        window.location.hash = "";
      })
      .catch((e) => {
        dispatch({ type: "SHOW_MESSAGE", payload: e.message });
      })
      .finally(() => {
        setIsSubmitting(false);
        dispatch({ type: "HIDE_LOADING" });
      });
  };

  return (
    <article>
      <LogoMobile />
      <Title content="Reset password" />

      <form method="POST" action="/dreamcatcher/reset" onSubmit={handleSubmit}>
        <p>
          <small>{language.passwordMustBe}</small>
        </p>
        <div className={css.field}>
          <Input
            onChange={onPasswordChange}
            value={password}
            uniqueId="f-card"
            label={language.newPassword}
            type="password"
            required={true}
          />
        </div>
        <div className={css.field}>
          <Input
            onChange={onConfirmPassword}
            value={confirmPassword}
            uniqueId="f-email"
            label={language.confirmNewPassword}
            type="password"
            required={true}
          />
        </div>
        <div className={css.submit}>
          <Button
            submit={true}
            label={language.resetPasswordButton}
            login={true}
            disabled={isSubmitting}
          />
        </div>
      </form>
    </article>
  );
}

export default ResetPassword;
